import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { PageLink } from './PageLink'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const WorkItem = ({ id, title, categories, image, slug, status }) => {
  const gatsbyImage = getImage(image)
  const clickItem = (title) => {
    trackCustomEvent({
      category: 'soft-conversion',
      action: 'portfolio-outbound-click',
      label: title,
    })
  }

  return (
    <div key={id} className={`col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 portfolio-box ${status}`}>
      <div className="cancel-anchor"></div>
      <PageLink to={`/work/${slug}`} onClick={() => clickItem(title)}>
        <div className="portfolio-image">
          <div className="dark-bg"></div>
          <GatsbyImage image={gatsbyImage} alt={slug} />
          <div className="portfolio-text">
            <h3>{title}</h3>
            <h6>{categories.map((cat) => cat.title).join(', ')}</h6>
          </div>
        </div>
      </PageLink>
    </div>
  )
}

export default WorkItem
