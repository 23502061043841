import React from 'react'

import ServiceItem from '../elements/ServiceItem'

function Services(props) {
  const services = [
    {
      icon: 'eye-alt',
      name: 'UI/UX Design',
      list: [
        'Data-driven Planning',
        'Customer Journey Mapping',
        'Conversion Optimisation',
        'ROI Maximisation',
      ]
    },
    {
      icon: 'ui-keyboard',
      name: 'Development',
      list: [
        'Custom Solutions',
        'Responsive Design',
        'Speed Optimisation',
        'Mobile Optimisation'
      ],
    },
    {
      icon: 'megaphone',
      name: 'Marketing',
      list: [
        'Klaviyo Email Automations',
        'Brand Identity Development',
        'UX/UI Innovation',
        'AI Content Generation',
      ],
    },
    {
      icon: 'life-buoy',
      name: 'Migration Support',
      list: [
        'Seamless Platform Transitions',
        'Data Preservation',
        'Risk-free Migrations',
        'Any platform to Shopify',
      ],
    },
  ]
  const displayServices = services.map((item, index) => <ServiceItem key={index} icon={item.icon} title={item.name} list={item.list} />)

  return (
    <section id="aboutServices" name="aboutServices">
      <div className="container-fluid-small">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 services-top services-left anim-bot">{props.children}</div>

          <div className="col-lg-1 col-xl-1"></div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
            <div className="row">{displayServices}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Services
