import React, { useState, useEffect } from 'react'

import WorkItem from '../elements/WorkItem'

function Works({ works, title }) {
  const [filter, setFilter] = useState('all')

  useEffect(() => {}, [filter])

  const workStatus = (work) => {
    return filter === 'all' || work.categories.some((cat) => cat.title === filter) ? '' : 'portfolio-hidden'
  }

  const categories = works.flatMap((work) => work.categories).map((cat) => cat.title)
  const uniqueCategories = [...new Set(categories)]
  const worksFilters = ['all'].concat(uniqueCategories)

  return (
    <section id="portfolioSection" className="section">
      <div className="container-fluid-small">
        <h2 className="anim-bot big-title">{title}</h2>

        <nav className="nav portfolio-nav anim-bot d-none d-sm-block">
          {worksFilters.map((item, i) => (
            <button key={i} className={`btn btn-lnk nav-item ${filter === item ? 'active' : null}`} onClick={() => setFilter(item)}>
              {item}
            </button>
          ))}
        </nav>

        <div className="row portfolio-row anim-bot-big">
          {works.map((work) => (
            <WorkItem
              key={work.id}
              id={work.id}
              title={work.client}
              categories={work.categories}
              image={work.portfolioImage}
              slug={work.slug}
              status={workStatus(work)}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Works
