import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { MARKS } from '@contentful/rich-text-types'

const SimpleText = ({ left, right, color, backgroundColor, reversedLayout }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <b style={{ color: '#3AA66D' }}>{text}</b>,
      [MARKS.ITALIC]: (text) => <b style={{ color: '#D79BDE' }}>{text}</b>,
      [MARKS.UNDERLINE]: (text) => <b style={{ color: '#D9AB57' }}>{text}</b>,
    },
  }
  return (
    <section className="simpleText" style={{ color, backgroundColor }}>
      <div className="container-fluid-small">
        <div className="row" style={{ flexDirection: reversedLayout ? 'row-reverse' : 'row' }}>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 anim-left">
            <div className="desc">{left && renderRichText(left)}</div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 anim-right">
            <div className="desc">{right && renderRichText(right, options)}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SimpleText
