import React from 'react'
import SimpleText from './SimpleText'
import SectionWithTwoImages from './SectionWithTwoImages'
import Works from '../components/Works'
import SectionWithOneImage from './SectionWithOneImage'
import SectionWithMultipleImages from './SectionWithMultipleImages'

const Section = ({ sectionObj }) => {
  if (sectionObj.internal.type === 'ContentfulWebComponentSimpleText') {
    return (
      <SimpleText
        left={sectionObj.left}
        right={sectionObj.right}
        color={sectionObj.textColor}
        backgroundColor={sectionObj.backgroundColor}
        reversedLayout={sectionObj.reversedLayout}
      />
    )
  }
  if (sectionObj.internal.type === 'ContentfulWebComponentSectionWithOneImage') {
    return <SectionWithOneImage {...sectionObj} />
  }
  if (sectionObj.internal.type === 'ContentfulWebComponentSectionWithTwoImages') {
    return <SectionWithTwoImages {...sectionObj} />
  }
  if (sectionObj.internal.type === 'ContentfulWebComponentSectionWithMultipleImages') {
    return <SectionWithMultipleImages {...sectionObj} />
  }
  if (sectionObj.internal.type === 'ContentfulWebComponentSectionWithWorks') {
    return <Works {...sectionObj} />
  }
  return <p></p>
}

export default Section
