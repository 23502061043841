import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import React, { FC, memo } from "react";
import { SectionWithOneImageInterface } from "../types/section.interfaces";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

type Props = SectionWithOneImageInterface;

const SectionWithOneImage: FC<Props> = ({ image, title, backgroundColor, imageFirst, textColor, content, button }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node, children) => children,
    },
  }

  const parsedImage = getImage(image);

  const parsedContent = !!content ? documentToReactComponents(JSON.parse(content.raw), options) : null;

  return (
    <section className="section section-with-one-image" style={{ backgroundColor: backgroundColor || "white" }}>
      <div className="section-with-one-image__wrapper container-fluid-small">
        <div style={{ order: imageFirst ? 1 : 2 }} className="section-with-one-image__image">
          <GatsbyImage image={parsedImage as any} alt={title} />
        </div>
        <div className="section-with-one-image__content" style={{ order: imageFirst ? 2 : 1 }}>
          <h2>{title}</h2>
          <p style={{ color: textColor || "black" }}>{parsedContent}</p>
          {!!button && <div className="section-with-one-image__content__btn-wrap">
            <a style={{ backgroundColor: button.buttonBgColor, color: button.buttonTextColor }} href={button.buttonUrl}>{button.buttonTitle}</a>
          </div>}
        </div>
      </div>
    </section>
  );
};

export default memo(SectionWithOneImage);