import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { MARKS } from '@contentful/rich-text-types'

function  AboutText(props) {
  let body = props.body
  
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <b>{text}</b>,
    },
  }

  return (
    <section id="aboutStory" className="section">
      <div className="container-fluid-small">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 story-left anim-bot">
            <h2 className="big-title anim-bot">
              Performance-driven developers, passionate about design
            </h2>
            {/* <PageLink to="/contact">Contact us</PageLink> */}
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 story-right anim-right">
            <div className="story">
              <p className="big-p">
                {renderRichText(body,options)}
              </p>
            </div>
          </div>

          {/* <div className="col-xl-3"></div>

          <div className="col-xl-3"></div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 story-bottom anim-bot">
            <ul>
              <li>7 Years of Experience</li>
              <li>22 Completed Projects</li>
              <li>41 Happy Customers</li>
            </ul>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 story-bottom anim-bot">
            <ul>
              <li>Award Winning Agency</li>
              <li>Aesthetics Oriented Team</li>
              <li>Marketing Strategy Experts</li>
            </ul>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default AboutText
