import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Hero from '../components/Hero'
import AboutText from '../components/AboutText'
// import Team from '../components/Team'
import Services from '../components/Services'
import Layout from '../components/Layout'
import { PageLink } from '../elements/PageLink'
import Section from '../components/Section'

const About = ({ data }) => {
  let heroSubTitle = 'Who We Are'
  let heroTitle = get(data, 'contentfulPageWebPage.subTitle')
  let body = get(data, 'contentfulPageWebPage.headline')
  const sections = get(data, 'contentfulPageWebPage.sections')  

  return (
    <Layout>
      <Hero title={heroTitle} sub={heroSubTitle} />
      <AboutText body={body} />
      {/* <Team team={team} /> */}      
      <Services>
        <h2 className="big-title">
          Helping you strive<br/>in the digital era.
        </h2>
        {/* <p>Helping you perform in the digital era.</p>  */}
        <PageLink to="/contact">Contact us</PageLink>
      </Services>
      {sections?.map((e) => (
        <Section sectionObj={e} key={e.id} />
      ))}     




    </Layout>
  )
}

export default About

export const query = graphql`
query aboutQuery {
  site {
    siteMetadata {
      title
    }
  }
  allContentfulWebComponentTeam {
    edges {
      node {
        id
        name
        function
        social {
          internal {
            content
          }
        }
        image {
          gatsbyImageData(
            aspectRatio: 1
            layout: FULL_WIDTH
            width: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
  contentfulPageWebPage(slug: {eq: "about"}) {
    id
    headline {
      raw
    }
    subTitle
    title
    sections {
      ... on Node {
        ... on ContentfulWebComponentSectionWithOneImage {
          id
          internal {
            type
          }
          backgroundColor
          imageFirst
          title
          textColor
          content {
            raw
          }
          image {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
          button {
            buttonBgColor
            buttonTextColor
            buttonTitle
            buttonUrl
            id
          }
        }
        ... on ContentfulWebComponentSectionWithTwoImages {
          id
          internal {
            type
          }
          title
          imageLeft {
            title
            excerpt
            image {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            button {
              buttonBgColor
              buttonTextColor
              buttonTitle
              buttonUrl
            }
          }
          imageRight {
            button {
              buttonBgColor
              buttonTextColor
              buttonTitle
              buttonUrl
            }
            excerpt
            title
            image {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        ... on ContentfulWebComponentSimpleText {
          id
          backgroundColor
          left {
            raw
          }
          right {
            raw
          }
          textColor
          title
          internal {
            type
          }
        }
        ... on ContentfulWebComponentSectionWithMultipleImages {
          id
          internal {
            type
          }
          title
          images {
            image {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
            button {
              buttonBgColor
              buttonTextColor
              buttonTitle
              buttonUrl
            }
            excerpt
            title
            url
          }
        }

        ... on ContentfulWebComponentSectionWithWorks {
          id
          internal {
            type
          }
          title
          works {
            id
            categories {
              title
              slug
            }
            date
            client
            slug
            featureOnHome
            portfolioImage {
              gatsbyImageData(aspectRatio: 1.5, width: 1680, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }    
  }
}
`
