import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { memo, FC } from "react";
import { SectionWithTwoImagesInterface } from "../types/section.interfaces";

type Props = SectionWithTwoImagesInterface;

const SectionWithTwoImage: FC<Props> = ({ id, imageLeft, imageRight }) => {

  const imageLeftImage = getImage((imageLeft as unknown as any).image);
  const imageRightImage = getImage((imageRight as unknown as any).image);

  return (
    <section className="section section__two-images" id={id}>
      <div className="section__two-images__row">
        <div className="section__two-images__column">
          <div className="section__two-images__column__image">
            <GatsbyImage image={imageLeftImage as IGatsbyImageData} alt="Image Left" />
          </div>
          <div className="section__two-images__column__content">
            {!!imageLeft.title && <h2 className="section__two-images__column__content__title">{imageLeft.title}</h2>}
            {!!imageLeft.excerpt && <p className="section__two-images__column__content__excerpt">{imageLeft.excerpt}</p>}
            <div className="btn-wrap">
              {!!imageLeft.button && <a href={imageLeft.button.buttonUrl} className="section__two-images__column__content__button">{imageLeft.button?.buttonTitle}</a>}
            </div>
          </div>
        </div>
        <div className="section__two-images__column">
          <div className="section__two-images__column__image">
            <GatsbyImage image={imageRightImage as IGatsbyImageData} alt="Image Left" />
          </div>
          <div className="section__two-images__column__content">
            {!!imageRight.title && <h2 className="section__two-images__column__content__title">{imageRight.title}</h2>}
            {!!imageRight.excerpt && <p className="section__two-images__column__content__excerpt">{imageRight.excerpt}</p>}
            <div className="btn-wrap">
              {!!imageRight.button && <a href={imageRight.button.buttonUrl} className="section__two-images__column__content__button">{imageRight.button?.buttonTitle}</a>}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
};

export default memo(SectionWithTwoImage);